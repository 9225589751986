import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs/operators';
import { ServiceErrorHandler } from '../utility/service-error-handler';
import { MobileProgram } from '../model/mobileprogram';
import { DriverDiscount } from '../model/driver-discount';
import { APICommonService } from '@nationwide/api-common-service';

@Injectable()
export class MobilePolicyInfoService {
    constructor(
        private _http: HttpClient,
        public _errorHandler: ServiceErrorHandler,
        private apiCommonService: APICommonService
    ) {
    }

    getMobileProgramData(policyNumber: string): Observable<MobileProgram> {
        return this.getMobilePolicyInfo(policyNumber).pipe(map((mobileProgram) =>
            this.mapMobileProgramData(mobileProgram)));
    }

    getMobilePolicyInfo(policyNumber: string): Observable<any> {
        const mobilePolicyURL = environment.telematicsProgramEnrollmentsService.url
            .replace(/POLICYNUMBER/, policyNumber);

        const reqHeader = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('X-NW-MessageID', this.apiCommonService.generateTransactionId())
            .set('client_id', environment.telematicsProgramEnrollmentsService.apiKey);

        return this._http.get(mobilePolicyURL, { headers: reqHeader })
            .pipe(
                catchError((error) => {
                    this._errorHandler.handleError('getMobilePolicyInfo');
                    return of(error);
                })
            );
    }

    mapMobileProgramData(userData: object): MobileProgram {
        const mobileProgram = new MobileProgram();
        const badRequestErrorCode = 400;
        if (Object.prototype.hasOwnProperty.call(userData, 'status') && userData['status'] === badRequestErrorCode) {
            sessionStorage.setItem('cancelledPolicyPresent', 'true');
            return mobileProgram;
        }
        mobileProgram.policyFinalDiscountPercent = userData['policyFinalDiscountPercent'];
        const drivers = userData['discounts'];
        for (const driver of drivers) {
            const driverData = new DriverDiscount();
            driverData.discountPercent = driver['discountPercent'];
            driverData.programEnrollmentDate = driver['programEnrollmentDate'];
            driverData.programCompletionDate = driver['programCompletionDate'];
            driverData.firstName = driver['driverFirstName'];
            driverData.lastName = driver['driverLastName'];
            mobileProgram.drivers.push(driverData);
        }
        return mobileProgram;
    }
}
