/* eslint-disable max-params */
import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoggerService } from 'app/service/logger.service';
import { UserService } from 'app/service/user.service';
import { DriverDetailsService } from 'app/service/driver-details.service';
import { TelematicsExperienceProgramDataService } from 'app/service/telematics-experience-program-data.service';

@Component({
    selector: 'app-mobile-number-update-card',
    templateUrl: './mobile-number-update-card.component.html',
    styleUrls: ['./mobile-number-update-card.component.css']
})
export class MobileNumberUpdateCardComponent {
    phoneRegex = new RegExp(/^[(]\d{3}[)][\s]\d{3}[-]\d{4}$/);
    regexStr = '^[0-9]+$';
    errorMessage: boolean;
    updatedMobileNumber: any;
    searchForm: UntypedFormGroup;
    submitButtonStatus: boolean;
    inputLengthCheck: boolean;
    loggedUserRole: string;
    inputLength = 10;
    @ViewChild('newMobileNumber', { static: false }) newMobileNumber: ElementRef;
    mobileView: boolean;
    driverDetails: any;
    driverData = [];
    srpProgramData: any;
    mobileDataDisplay: boolean;
    errorBanner: boolean;
    updateApiSuccessMessage = false;
    updateApiErrorMessage = false;
    getDataError: boolean;
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.checkInnerWidth(window.innerWidth);
    }

    constructor(
      private formBuilder: UntypedFormBuilder,
      private logger: LoggerService,
      public user: UserService,
      public driverDetailsService: DriverDetailsService,
      public srmProgramDataSerivce: TelematicsExperienceProgramDataService
      ) {
        this.submitButtonStatus = true;
        this.searchForm = this.formBuilder.group({
            mobileNumber: [this.updatedMobileNumber, [
                Validators.pattern(/^[(]\d{3}[)][\s]\d{3}[-]\d{4}$/)
            ]]
        });
    }

    ngOnInit(): void {
        this.checkInnerWidth(window.innerWidth);
        this.userRole();
        this.srmProgramDataSerivce.getSrpMobileProgramDetails(this.user.getPolicyNumber()).subscribe((response) => {
            this.srpProgramData = response;
            if (this.srpProgramData.error) {
                this.getDataError = true;
                this.logger.error('SRM program details api failed with error', {
                    error: this.srpProgramData.error,
                    policy: this.user.getPolicyNumber()
                });
            } else {
                this.srpProgramData = this.srpProgramData.programDetails.filter((srpData) => (srpData.programType === 'SRMCM' || srpData.programType === 'SRM') && srpData.dataCollectionStatusCode !== 'X');
                this.mobileDataDisplay = false;
                this.getDataError = false;
                if (this.srpProgramData.length > 0) {
                    for (const srpData of this.srpProgramData) {
                        this.getCMTData(srpData);
                    }
                } else {
                    this.getDataError = true;
                }
            }
        });
    }

    getCMTData(srpData): void {
        this.driverDetailsService.getSrmUserDetails(srpData.dataCollectionId).subscribe((response) => {
            const programData = response;
            if (programData.users) {
                if (srpData.driverDetails.driverSuffix) {
                    programData.users[0].suffix = srpData.driverDetails.driverSuffix;
                }
                this.driverData.push(programData.users[0]);
                if (this.driverData.length === this.srpProgramData.length) {
                    this.mobileDataDisplay = true;
                    this.driverDetails = this.driverData;
                }
            } else if (programData.error) {
                this.getDataError = true;
                this.logger.error('SRM get cmt api failed with error', {
                    error: programData.error
                });
            }
        });
    }

    userRole(): void {
        if (sessionStorage.getItem('isAgent') === 'true') {
            this.loggedUserRole = 'Agent';
        } else if (sessionStorage.getItem('isAdmin') === 'true') {
            this.loggedUserRole = 'Admin';
        } else {
            this.loggedUserRole = 'Member';
        }
        this.logger.info(`${this.loggedUserRole} landed on SRM Update Mobile Number page`, {
            role: this.loggedUserRole
        });
    }

    changeMobileNumber(): void {
        this.updateApiSuccessMessage = false;
        this.updateApiErrorMessage = false;
        this.logger.info('SRM update button clicked', {
            role: this.loggedUserRole
        });
        if (this.driverDetails) {
            // eslint-disable-next-line guard-for-in
            for (const index in this.driverDetails) {
                const newMobileValue = document.getElementById(`updatedNumberValue-${index}`).textContent;
                if (newMobileValue) {
                    const formattedMobileValue = `1${newMobileValue.replace(/\D/g, '')}`;
                    this.driverDetailsService
                        .updateDriverMobileNumber(
                            this.driverDetails[index],
                            formattedMobileValue
                        )
                        .subscribe((response) => {
                            if (response.error) {
                                this.updateApiErrorMessage = true;
                            } else {
                                this.driverDetails[index].mobile = response.users[0].mobile;
                                document.getElementById(`updatedNumberValue-${index}`).setAttribute('maxlength', `10`);
                                // eslint-disable-next-line no-extra-parens
                                (<HTMLInputElement>document.getElementById(`updatedNumberValue-${index}`)).value = '';
                                this.submitButtonStatus = true;
                                this.updateApiSuccessMessage = true;
                                this.logger.info('SRM Update api success', {
                                    role: this.loggedUserRole,
                                    userId: this.user.getUsername(),
                                    policyNumber: this.user.getPolicyNumber()
                                });
                            }
                        });
                }
            }
        }
    }

    checkFormStatus(index: number): any {
        this.updatedMobileNumber = this.searchForm.get('mobileNumber').value;
    }

    onKeyPress($event): any {
        return new RegExp(this.regexStr).test($event.key);
    }

    textChange(event: any, index): void {
        const mobileNumberLength = 9;
        event.target.value = event.target.value.replace(/(\d{3})-?(\d{3})-?(\d{4})/, '($1) $2-$3');
        document.getElementById(`updatedNumberValue-${index}`).textContent = event.target.value;
        if (event.target.value.length > mobileNumberLength) {
            this.inputLengthCheck = true;
            document.getElementById(`updatedNumberValue-${index}`).setAttribute('maxlength', `14`);
        }
        this.checkUpdateButtonStatus(event.target.value, index);

        this.getErrorMessageForForm(event.target.value.length, index);
    }

    getErrorMessageForForm(updatedNumber, index): any {
        const valueLength = document.getElementById(`updatedNumberValue-${index}`).getAttribute('maxlength');
        if (updatedNumber < Number(valueLength) && updatedNumber && valueLength === '14') {
            document.getElementById(`errorMessage-${index}`).textContent = 'Enter a valid number';
            document.getElementById(`errorIcon-${index}`).setAttribute('style', 'display: flex');
        } else {
            document.getElementById(`errorMessage-${index}`).textContent = '';
            document.getElementById(`errorIcon-${index}`).setAttribute('style', 'display: none');
        }
        if (valueLength === '14' && updatedNumber === 0) {
            document.getElementById(`updatedNumberValue-${index}`).setAttribute('maxlength', `10`);
        }
    }

    focusOut(event, index): any {
        const value = event.target.value.length;
        const valueLength = document.getElementById(`updatedNumberValue-${index}`).getAttribute('maxlength');
        const phoneNumberLengthRequirement = 14;
        if (valueLength === '14' && value !== 0 && value < phoneNumberLengthRequirement) {
            document.getElementById(`errorMessage-${index}`).textContent = 'Enter a valid number';
            document.getElementById(`errorIcon-${index}`).setAttribute('style', 'display: flex');
        }
    }

    paste($event, index): any {
        const trimmedText = $event.target.value.replace(/\D/g, '');
        const valueLength = document.getElementById(`updatedNumberValue-${index}`).getAttribute('maxlength');
        if (trimmedText.length === valueLength) {
            (<HTMLInputElement>document.getElementById(`updatedNumberValue-${index}`)).value = trimmedText.replace(/(\d{3})-?(\d{3})-?(\d{4})/, '($1) $2-$3');
        } else {
            (<HTMLInputElement>document.getElementById(`updatedNumberValue-${index}`)).value = trimmedText;
            document.getElementById(`errorMessage-${index}`).textContent = 'Enter a valid number';
            document.getElementById(`errorIcon-${index}`).setAttribute('style', 'display: flex');
        }
    }

    checkUpdateButtonStatus(updatedNumber, index): any {
        const valueLength = document.getElementById(`updatedNumberValue-${index}`).getAttribute('maxlength');
        if (updatedNumber && updatedNumber.length === Number(valueLength)) {
            this.checkNumberFieldStatus();
        } else if (this.newMobileNumber.nativeElement.value) {
            this.checkNumberFieldStatus();
        } else {
            this.submitButtonStatus = true;
        }
    }

    updateNumberLogger(): void {
        this.logger.info('SRM Update Mobile Number - Clicked "Update" button', {
            policyNumber: this.user.getPolicyNumber(),
            role: this.loggedUserRole
        });
    }

    checkNumberFieldStatus(): any {
        let numberCount = 0;
        if (this.driverDetails) {
            // eslint-disable-next-line guard-for-in
            for (const index in this.driverDetails) {
                const mobileValue = document.getElementById(`updatedNumberValue-${index}`).textContent;
                if (this.phoneRegex.test(mobileValue) || !mobileValue) {
                    numberCount++;
                } else if (numberCount > 0) {
                    numberCount--;
                }
                if (numberCount === this.driverDetails.length) {
                    this.submitButtonStatus = false;
                } else {
                    this.submitButtonStatus = true;
                }
            }
        }
    }

    checkInnerWidth(screenWidth: number): void {
        this.mobileView = false;
        const maxMobileWidth = 768;
        if (screenWidth < maxMobileWidth) {
            this.mobileView = true;
        }
    }
}
