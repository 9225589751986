import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AdminGuard } from 'app/router-guards/admin-guard.service';
import { AgentGuard } from 'app/router-guards/agent-guard.service';
import { AdminSearchFormService } from 'app/components/admin/admin-search-form.service';
import { UntypedFormGroup } from '@angular/forms';
import { Vehicle } from 'app/model/vehicle';
import { VehicleService } from 'app/service/vehicle.service';
import { PersonalLinesPolicyService } from 'app/service/personal-lines-policy.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BrandingService } from 'app/service/branding.service';
import { tap } from 'rxjs/operators';
import { PolicyNumberFormatterPipe } from '@nationwide/internet-servicing-angular-pipes';
import { LoggerService } from 'app/service/logger.service';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { DataService } from 'app/service/data.service';
import { SelfEnrollmentService } from 'app/service/self-enrollment.service';
import { WindowRef } from 'app/service/window-ref';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
    vin: string;
    policyNumber: string;
    searchForm: UntypedFormGroup;
    vehicles: Vehicle[] = [];
    isAdmin = true;
    isMobileProgram = false;
    mobilePolicy = '';
    inputErrorMessage = '';
    effectiveDate: string;
    versionData: string;
    vehicleModel: Vehicle;
    timeframe: TimeFrame;
    vehicleEnrollments: any;
    vehicleId: any;
    versionDataArray = [];
    agentRoute: any;
    accessToken: any;
    policyQueryparam: string;
    userRole: string;
    agentAccessToken: any;
    currentSessionStorageUserRole: string;
    loadWaitIndicator: any;
    @ViewChild('waitIndicator', { static: false }) waitIndicator: ElementRef;
    private _window: any;

    // eslint-disable-next-line max-params
    constructor(
        public adminSearchForm: AdminSearchFormService,
        public vehicleService: VehicleService,
        public titleService: Title,
        public policy: PersonalLinesPolicyService,
        public router: Router,
        private policyNumberFormatterPipe: PolicyNumberFormatterPipe,
        private logger: LoggerService, public route: ActivatedRoute,
        private dataService: DataService,
        private selfEnrollmentService: SelfEnrollmentService,
        private _windowRef: WindowRef
    ) { }

  ngOnInit(): void {
        this._window = this._windowRef.nativeWindow;
        if (sessionStorage.getItem('isAgent')) {
          this.userRole = 'Agent';
        }
        this.setTitle('SmartRide');
        sessionStorage.removeItem('selectedPolicy');
        sessionStorage.removeItem('allPolicies');
        this.searchForm = this.adminSearchForm.buildForm();
        this.dataService.setUser(this.userRole);
        this.isAdmin = this.dataService.getIsAdmin();

        // Role Splunk Logging
        // Reading roles directly from session storage due to current issues with role data service (data.service.ts)

        if (sessionStorage.getItem('isAgent') === 'true') {
          this.currentSessionStorageUserRole = 'Agent';
        } else if (sessionStorage.getItem('isAdmin') === 'true') {
          this.currentSessionStorageUserRole = 'Admin';
        } else {
          this.currentSessionStorageUserRole = 'Member';
        }

        this.logger.info('Landed on Admin Search Page', {
          role: this.currentSessionStorageUserRole
        });
    }

    checkForm(): void {
        this.inputErrorMessage = this.getErrorMessageFromForm();
        if (this.inputErrorMessage) {
            this.vin = '';
            this.policyNumber = '';
        } else {
            this.vin = (this.searchForm.get('vin').value || '').trim();
            this.policyNumber = this.policyNumberFormatterPipe.transform((this.searchForm.get('policyNumber').value || '').trim());
        }
    }

    getErrorMessageFromForm(): string {
        if (this.searchForm.valid) {
            return '';
        }

        if (this.searchForm.get('policyNumber').status === 'INVALID') {
            return 'Invalid policy number.';
        }

        if (this.searchForm.get('vin').status === 'INVALID') {
            return 'Invalid VIN.';
        }

        return 'Failed input validation.';
    }

    submitSearch(): void {
        sessionStorage.removeItem('selectedPolicy');
        this.checkForm();
        if (this.inputErrorMessage) {
            return;
        }
        if (!this.vin && !this.policyNumber) {
            this.inputErrorMessage = 'Please enter a VIN or Policy Number below.';
            return;
        }

        this.vehicles = [];
        this.isMobileProgram = false;
        this.mobilePolicy = '';

        if (this.userRole === 'Agent') {
          try {
            this._window.document.getElementById('fullScreenWait').show();
            this.vehicleService.agentPolicyAccessCheck(this.policyNumber.toUpperCase()).subscribe((response) => {
                if (this.policyNumber.toUpperCase() === response.policyNumber) {
                    this.checkForMobileEnrollmentType();
                } else {
                    this.setErrorMsgText();
                }
            }, (error) => {
                this.logger.error('Failed agent error: ', error);
                this.setErrorMsgText();
            });
          } catch (e) {
            this.logger.error('Failed agent error: ', e);
            this.setErrorMsgText();
          }
        } else if (this.currentSessionStorageUserRole === 'Admin') {
            this._window.document.getElementById('fullScreenWait').show();
            if (!this.vin) {
                this.checkForMobileEnrollmentType();
            } else if (this.vin) {
                this.getVehicleData();
            }
        }
    }

    checkForMobileEnrollmentType(): void {
        this.selfEnrollmentService.checkForMobileEnrollmentForAdminSearch(this.policyNumber.toUpperCase()).subscribe((response) => {
            if (response?.mobileEnrollment?.mobileEnrollmentType === 'MobileApp' || response?.mobileEnrollment?.mobileEnrollmentType === 'MobileAppEarlyDiscount') {
                this.storePolicyNumberInSession(this.policyNumber);
                this._window.document.getElementById('fullScreenWait').hide();
                this.router.navigateByUrl(`/mobileNumberUpdate`);
            } else {
                this.getVehicleData();
            }
        }, (error) => {
            this.logger.error(`Telematics enrollment call in admin search failed with ${error}`);
            this.setErrorMsgText();
        });
    }

    getVehicleData(): void {
        this.vehicleService.getVehiclesForAdmin(this.vin.toUpperCase(), this.policyNumber.toUpperCase()).pipe(tap(
            (responses) => {
                this.receiveVehiclesResponse(responses);
            }
        )).subscribe(() => { },
            (error) => {
                this.handleVehiclesServiceError(error);
            }
        );
    }

    receiveVehiclesResponse(responses): void {
        this.isMobileProgram = false;
        this.mobilePolicy = '';

        if (responses.length < 1) {
            this.setErrorMsgText();
            return;
        }

        this.storePolicyNumberInSession(responses[0].policyNumber || this.policyNumber);

        for (const response of responses) {
            this.buildVehicleModelFromServiceResponse(response);
        }
    }

    handleVehiclesServiceError(error): boolean {
        this.logger.error('SRE Admin - Data retrieval/back-end error', {
          policyNumberInput: this.policyNumber,
          vinInput: this.vin,
          role: this.currentSessionStorageUserRole,
          errorCode: error.status
          // errorMessage: error.error.developerMessage
        });
        // eslint-disable-next-line no-magic-numbers
        if (error.status === 401 && error.error.developerMessage.indexOf('Access Token') !== -1) {
            // TODO Can we reauthorize and repeat the call instead of refreshing
            sessionStorage.removeItem('tokenDetails');
            this.router.navigateByUrl('/smartrideAdminReload');
            return;
        } else {
            this.setErrorMsgText();
            return false;
        }
    }

    storePolicyNumberInSession(policyNumber): void {
        sessionStorage.setItem('selectedPolicy', policyNumber);
        sessionStorage.setItem('allPolicies', policyNumber);
    }

    buildVehicleModelFromServiceResponse(response): void {
        if (!response.vin) {
            return;
        }

        if (response.isEnrolledInSRM) {
            this.isMobileProgram = true;
            this.mobilePolicy = response.mobilePolicyNumber;
        }

        const vehicle = new Vehicle(response);
        if (vehicle.scoringModel === 'SM1' || vehicle.scoringModel === 'SM2') {
            BrandingService.setBranding('smartmiles');
        } else {
            BrandingService.setBranding('smartride');
        }
        this.addVehicleToList(vehicle);
    }

    addVehicleToList(vehicle): void {
        this.vehicles.push(vehicle);
        this.vehicles.sort((a: Vehicle, b: Vehicle) => {
            if (a.deviceStatusTimeline && b.deviceStatusTimeline) {
                if (a.deviceStatusTimeline.completionDate > b.deviceStatusTimeline.completionDate) {
                    return -1;
                } else if (a.deviceStatusTimeline.completionDate < b.deviceStatusTimeline.completionDate) {
                    return 1;
                }
            }
            return 0;
        });
        this._window.document.getElementById('fullScreenWait').hide();
    }

    setErrorMsgText(): boolean {
        if (this.policyNumber) {
            this.inputErrorMessage = `No results were found for Policy Number ${this.policyNumber}`;
        } else if (this.vin) {
            this.inputErrorMessage = `No results were found for VIN ${this.vin}`;
        } else {
            this.inputErrorMessage = 'Please enter a VIN or Policy Number below.';
        }
        this.logger.error('SRE Admin - Form input validation error', {
          policyNumberInput: this.policyNumber,
          vinNumberInput: this.vin,
          role: this.currentSessionStorageUserRole,
          errorMessage: this.inputErrorMessage
        });
        this._window.document.getElementById('fullScreenWait').hide();
        return false;
    }

    setTitle(newTitle: string): void {
        this.titleService.setTitle(newTitle);
    }
}
